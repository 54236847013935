import AutoNumeric from 'autonumeric'

document.addEventListener 'turbolinks:load', ->
  autonumerics = document.getElementsByClassName 'autonumeric'
  return false unless autonumerics.length

  new AutoNumeric.multiple('.autonumeric', {
      currencySymbol: '$'
      decimalPlaces: 0
      unformatOnSubmit: true
    }
  )
